.wrap {
  min-height: var(--spasing-m);
  min-width: var(--spasing-m);
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.input {
  margin: 0;
  height: 0;
  width: 0;
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radio, .label {
  cursor: pointer;
}

.radio {
  width: 18px;
  height: 18px;
  margin: calc((var(--spasing-m) - 18px) / 2);
  border: var(--border-width-s) solid var(--color-100);
  border-radius: 50%;
  position: absolute;
  left: 0;
}

.radio:after {
  content: "";
  display: none;
  width: 70%;
  height: 70%;
  background-color: var(--color-100);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.input:checked ~ .radio:after {
  display: block;
}

.label {
  margin-inline-start: calc(var(--spasing-m) + var(--spasing-xs));
  color: var(--color-70);
  font-size: var(--font-size-s);
}

.input:disabled,
.input:disabled ~ .radio,
.input:disabled ~ .label {
  cursor: not-allowed;
}

.input:disabled ~ .radio {
  border-color: var(--color-50);
}

.input:disabled ~ .radio:after {
  background-color: var(--color-50);
}

.input:disabled ~ .label {
  color: var(--color-50);
}