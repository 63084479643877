.wrap {
  border: 2px solid var(--color-40);
  border-radius: var(--border-radius-1);
  margin-bottom: var(--spasing-s);
}

.accordion {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-40);
  padding: var(--spasing-xs);
  border-radius: var(--border-radius-1);
  margin-bottom: var(--spasing-xs);
  width: calc(100% + 4px);
  margin-left: -2px;
}

.container {
  display: grid;
  grid-template-columns: repeat(auto-fit, 180px);
  gap: 12px;
  padding: var(--spasing-xs);
}

.card {
  border: 1px solid var(--color-40);
  border-radius: var(--border-radius-1);
  padding: var(--spasing-xs);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  text-align: center;
  margin-top: var(--spasing-xxs);
}

.videoResponsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.videoResponsive iframe {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 95%;
  width: 95%;
  position: absolute;
  border: 0px;
}

.icon {
  color: var(--color-110);
}

.button {
  border: 1px solid var(--color-40);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  padding: 2px;
  width: 28px;
  height: 28px;
  margin-top: var(--spasing-xs);
}

.inputWrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: var(--spasing-xs);
}

.input {
  min-width: 384px;
  margin-right: var(--spasing-m);
}

.submitButton {
  max-width: 196px;
}
