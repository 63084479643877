.label {
  margin-block-end: var(--spasing-xs);
  margin-block-start: var(--spasing-s);
  color: var(--color-50);
  font-size: var(--font-size-s);
}

.value {
  min-height: 1.2em;
  color: var(--color-70);
  font-size: var(--font-size-s);
}