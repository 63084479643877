.form {
  width: 100%;
}

.inputGroupWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spasing-m) var(--spasing-xl);
  padding: 0 var(--spasing-m);
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid var(--color-40);
  padding-top: var(--spasing-s);
  padding-bottom: var(--spasing-s);
  padding-right: var(--spasing-s);
}

.buttonSubmit {
  max-width: 115px;
}

.buttonReset {
  max-width: 115px;
  background-color: var(--shadow-10);
  color: var(--color-80);
  border-color: var(--color-80);
  margin-right: var(--spasing-s);
}

.button:hover {
  background-color: transparent;
}