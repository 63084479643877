.switch {
  position: relative;
  margin-left: 18px;
  width: 16px;
  height: 30px;
  transition: background-color 100ms ease-out;
  cursor: pointer;
  z-index: 1;
}
.switch:before, .switch:after {
  content: "";
  position: absolute;
  top: 0;
  background-color: inherit;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  z-index: 2; }
.switch:before {
  left: -1rem;
}
.switch:after {
  right: -1rem;
}

.toggleButton {
  position: absolute;
  width: 29px;
  height: 29px;
  background-color: var(--color-40);
  border-radius: 50%;
  transition: transform 100ms ease-in-out;
  z-index: 3;
  top: 0.5px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
 }

.componentWrapperIsLight {
  background-color: white;
}

.switchIsOff {
  width: 15px;
  margin-left: 18px;
  background-color: var(--color-30);
}

.switchIsOn {
  background-color: var(--color-80);
}

.toggleButtonPositionLeft {
  transform: translateX(-15px);
}

.toggleButtonPositionRight {
  transform: translateX(2px);
}