.addButton:hover {
  background-color: transparent;
}

.addButton svg {
  fill: currentColor;
}

.input {
  display: block;
  position: absolute;
  top: 12px;
  width: 110px;
  right: 52px;
  opacity: 0;
  cursor: pointer;
}