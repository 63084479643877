.wrap {
  padding: var(--spasing-s);
}

.icon {
  width: 20px;
  min-width: 20px;
  height: 20px;
  transform: rotate(-90deg);
}

.title {
  margin-inline-start: var(--spasing-xs);
  color: var(--color-60);
  font-size: var(--font-size-s);
  text-transform: uppercase;
}