.wrap {
  padding: 0;
}

.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spasing-s);
  padding-bottom: var(--spasing-s);
}

.bottomLine {
  width: 100%;
  height: 1px;
  background-color: var(--color-30);
  width: calc(100% + 32px);
  margin-left: -16px;
}

.btn {
  max-width: 230px;
  margin: var(--spasing-m) auto var(--spasing-xs);
}

.addAddressBtn {
  color: var(--color-80);
  font-weight: 500;
  margin-top: var(--spasing-m);
  margin-bottom: var(--spasing-m);
  border-radius: 8px;
}

.addAddressBtn:hover {
  background: transparent;
}

.addAddressBtn svg {
  margin-right: var(--spasing-xs);
}
