.shutter {
  width: 100%;
  height: 100%;
  box-shadow: -2px 0 4px rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  overflow: auto;
  z-index: calc(var(--header-z-index) + var(--header-z-index) + 2);
}

.withHeader {
  top: var(--header-height);
  height: calc(100% - var(--header-height));
}

.left {
  left: 0;
  border-radius: 0 10px 10px 0;
}

.right {
  right: 0;
  border-radius: 10px 0 0 10px;
}

.fullWidth {
  max-width: 100%;
}

.fullWidth.left,
.fullWidth.right {
  border-radius: 0;
}

.left,
.left.enter {
  transform: translateX(-100%);
}

.left.enterActive {
  transform: translateX(0);
  transition: transform 300ms;
}

.left.enterDone {
  transform: translateX(0);
}

.left.exit {
  transform: translateX(0);
}

.left.exitActive {
  transform: translateX(-100%);
  transition: transform 300ms;
}

.left.exitDone {
  transform: translateX(-100%);
}

.right,
.right.enter {
  transform: translateX(100%);
}

.right.enterActive {
  transform: translateX(0);
  transition: transform 300ms;
}

.right.enterDone {
  transform: translateX(0);
}

.right.exit {
  transform: translateX(0);
}

.right.exitActive {
  transform: translateX(100%);
  transition: transform 300ms;
}

.right.exitDone {
  transform: translateX(100%);
}

.overlayModal {
  display: flex;
  justify-content: center;
  padding: var(--spasing-m) 0 var(--spasing-m) 0;
  background-color: rgba(0, 0, 0, 0.4);
}
