.wrapper {
  height: 100vh;
}

.image {
  display: inline-block;
  width: 5rem;
  height: 5rem;
  vertical-align: -0.125em;
  opacity: 0;
  -webkit-animation: spinner-grow 0.75s ease-out infinite;
  animation: spinner-grow 0.75s ease-out infinite;
}


@-webkit-keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}