.wrap {
  padding: 0 var(--spasing-s);
}

.container {
  border-bottom: 1px solid var(--color-40);
  margin-bottom: var(--spasing-s);
}

.btn {
  padding: var(--spasing-xs) var(--spasing-xl);
}

.icon {
  margin-right: var(--spasing-xs);
  display: flex;
  align-items: center;
}
