.wrap {
  position: relative;
}

.showControls .input {
  width: 100%;
  padding-inline-end: calc(40px + var(--border-radius-1));
}

.input {
  width: 100%;
  height: 40px;
  padding: 0 var(--border-radius-1);
  border: var(--border-width-xs) solid var(--color-40);
  border-radius: var(--border-radius-1);
  color: var(--color-70);
  font-size: var(--font-size-s);
}

.input::placeholder {
  color: var(--color-50);
}

.input:focus {
  border-color: var(--color-60);
}

.input:disabled {
  background-color: var(--color-20);
  border-color: var(--color-40);
  color: var(--color-50);
}

.controls {
  display: none;
  width: 40px;
  height: calc(100% - (2 * var(--border-width-xs)));
  position: absolute;
  right: var(--border-radius-1);
}

.showControls .controls {
  display: flex;
}

.button {
  height: 100%;
  width: 100%;
}

.button.active {
  color: darkgreen;
}
