.icon {
    width: 200px;
    height: 200px;
    fill: transparent;
    stroke: var(--color-50);
}

.title {
    font-size: 2em;
    color: var(--color-50);
}