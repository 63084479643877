.list {
  list-style: none;
  padding: 0;
  margin: 0;
  border-top: var(--border-width-xs) solid var(--color-30);
  border-bottom: var(--border-width-xs) solid var(--color-30);
}

.item + .item {
  border-top: var(--border-width-xs) solid var(--color-30);
}

.link {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 40px;
  padding: var(--spasing-xs) var(--spasing-s);
  background-color: var(--color-10);
  color: var(--color-70);
  font-size: var(--font-size-s);
  font-weight: 500;
}

.active {
  color: var(--color-100);
}

.icon {
  width: 24px;
  min-width: 24px;
  height: 24px;
  margin-inline-end: var(--spasing-xs);
}