.wrap {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
}

.column {
  /*display: block;*/
  position: relative;
}

.twoColumns_50X50 .column {
  width: 50%;
  min-height: 100vh;
  position: relative;
}

.twoColumns_fluid .column {
  padding: var(--spasing-s);
}

.twoColumns_fluid .column:last-child {
  width: 100%;
}

.column:empty {
  padding: 0;
}
