.form {
  width: 100%;
  background-color: var(--color-10);
  padding:  var(--spasing-s) var(--spasing-s) 0 var(--spasing-s);
  border-bottom-style:  solid;
  border-left-style: solid;
  border-right-style: solid;
  border-color: var(--color-30);
  border-radius: 0px 0px 20px 20px;
}

.currency{
  min-width: 180px;
  z-index: 0;
}

.dateField { 
  width: 350px;
}

.duplicateButton{
  margin-inline-start: var(--spasing-s);
  margin-inline-end: var(--spasing-s);
  border-color: var(--color-80);
  color: var(--color-80)
}

.footer {
  min-height: 75px;
  align-items: center;
  padding-inline-start: var(--spasing-s);
  padding-inline-end: var(--spasing-s);
  border-top: 1px solid var(--color-40);
}

.deleteButton {
   border-color: var(--color-100);
   color: var(--color-100)
}

.statusGroup {
  justify-content: center;
  min-height: calc(30px + var(--spasing-s) + (var(--font-size-m) * 1.2));
}

.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: var(--spasing-l);
}

.inputGroup + .inputGroup {
  margin-block-start: var(--spasing-s);
}

.inputInline {
  margin-block-end: 0;
}

.inputInline + .inputInline {
  margin-inline-start: var(--spasing-m);
}