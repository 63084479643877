body {
  margin: 0;
  font-family: var(--font-family-1);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  outline: none;
}

html {
  text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
  font-size: 14px;
}

html,
body {
  border: none;
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

a {
  color: inherit;
  text-decoration: none;
}

.react-datepicker-popper {
  z-index: 3;
}