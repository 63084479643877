.wrap {
  border: 2px solid var(--color-40);
  border-radius: var(--border-radius-1);
  margin-bottom: var(--spasing-s);
}

.container {
  display: grid;
  grid-template-columns: repeat(auto-fit, 180px);
  gap: 12px;
  padding: var(--spasing-xs);
}

.card {
  border: 2px solid var(--color-40);
  border-radius: var(--border-radius-1);
  margin-bottom: var(--spasing-s);
  padding: var(--spasing-xs);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.accordion {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-40);
  padding: var(--spasing-xs);
  border-radius: var(--border-radius-1);
  margin-bottom: var(--spasing-xs);
  width: calc(100% + 4px);
  margin-left: -2px;
}

.accordion svg {
  height: 32px;
  width: 32px;
}

.accordion button:hover {
  background-color: transparent;
}

.image {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spasing-xxs);
  margin-bottom: var(--spasing-xs);
}

.title {
  text-align: center;
  margin-bottom: var(--spasing-xxs);
}

.icon {
  color: var(--color-110);
}

.button {
  border: 1px solid var(--color-40);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  padding: 2px;
  width: 28px;
  height: 28px;
  margin-top: var(--spasing-xs);
}

.dropdownCard {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dotted #5d6370;
  margin-bottom: var(--spasing-s);
  border-radius: var(--border-radius-1);
  min-height: 180px;
}

.buttonWrap {
  position: relative;
  height: 40px;
  width: 190px;
  margin-left: 12px;
  margin-bottom: var(--spasing-s);
}

.inputUpload {
  position: absolute;
  top: 5px;
  left: 12px;
  cursor: pointer;
  opacity: 0;
}

.iconWrap {
  transform: rotate(180deg);
}

.iconWrap svg {
  width: 48px;
  height: 48px;
}
