.wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}

.title {
  font-size: 28px;
  line-height: 33px;
  padding-left: var(--spasing-xl);
}

.buttonWrap {
  display: flex;
}

.button {
  margin-left: var(--spasing-m);
  min-width: 192px;
}

.button:not(:last-child) {
  border: 1px solid var(--color-00);
}
