.item {
  white-space: nowrap;
}

.link {
  height: 20px;
  line-height: 20px;
  color: var(--color-70);
  font-size: var(--font-size-m);
  font-weight: 600;
  padding-inline-start: 8px;
  padding-inline-end: 8px;
}

.active {
  color: var(--color-90);
  pointer-events: none;
}
