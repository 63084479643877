.reference {
  font-weight: bold;
  text-wrap: nowrap;
  margin-bottom: var(--spasing-xs);
}

.url {
  max-width: 272px;
  height: 40px;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--color-80);
  border: 1px solid var(--color-50);
  border-radius: var(--border-radius-3);
  padding: var(--spasing-xs);
  margin-right: var(--spasing-s);
}

.btn {
  border: 1px solid var(--color-50);
  width: 128px;
}

.btn:hover {
  cursor: pointer;
}

.device {
  font-weight: bold;
  margin-right: var(--spasing-s);
}
