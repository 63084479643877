.button {
  width: auto;
  background-color: inherit;
}

.button:hover {
  background-color: inherit;
}

.icon {
  fill: var(--color-70);
}
