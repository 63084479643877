.wrap {
  max-width: 257px;
  width: 257px;
  padding-bottom: 0;
}

.header {
  margin-block-end: var(--spasing-s);
  color: var(--color-70);
  font-size: var(--font-size-m);
}

.clearButton {
  color: var(--color-90);
  font-size: var(--font-size-s);
  font-weight: 500;
  text-transform: none;
  justify-content: left;
  padding-left: 0;
}

.clearButton:hover {
  background: transparent;
  text-decoration: underline;
}

.item {
  border-color: var(--color-90);
  margin-block-end: var(--spasing-xs);
  margin-inline-end: var(--spasing-xs);
}

.item:hover {
  background: transparent;
}