.text {
  margin-block-start: var(--spasing-s);
  margin-block-end: var(--spasing-s);
  color: var(--color-60);
  font-size: var(--font-size-s);
  text-align: center;
}

.button {
  margin-block-start: var(--spasing-xs);
}