.container {
  background-color: var(--color-10);
  border-radius: 24px;
  padding: var(--spasing-xl);
  margin-bottom: var(--spasing-m);
}

.inputWrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: var(--spasing-m);
}

.button {
  border: 1px solid var(--color-00);
  margin-right: 24px;
}
