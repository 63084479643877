.header {
  min-height: 56px;
  background-color: var(--color-20);
  padding-inline-start: var(--spasing-s);
  padding-inline-end: var(--spasing-s);
  text-transform: uppercase;
}

.title {
  color: var(--color-70);
  font-size: var(--font-size-l);
}