.container {
  background-color: var(--color-10);
  border-radius: 24px;
  padding: var(--spasing-xl);
  margin-bottom: var(--spasing-m);
}

.inputWrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.input {
  min-width: 384px;
  margin-right: var(--spasing-m);
}

.button {
  max-width: 196px;
}
