.img {
  width: 82px;
  height: auto;
  object-fit: contain;
}

.videoResponsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  /* margin-bottom: var(--spasing-xs); */
}

.videoResponsive iframe {
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 80%;
  width: 80%;
  position: absolute;
  border: 0px;
}
