.form {
  width: 100%;
  background-color: var(--color-10);
  padding: var(--spasing-s);
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  border-color: var(--color-30);
  border-radius: 0px 0px 20px 20px;
}

.currency {
  min-width: 130px;
}

.container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: var(--spasing-l);
}

.inputGroup + .inputGroup {
  margin-block-start: var(--spasing-s);
}

.inputInline {
  margin-block-end: 0;
}

.inputInline + .inputInline {
  margin-inline-start: var(--spasing-m);
}
