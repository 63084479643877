.wrap {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
}

.column {
  /*display: block;*/
  position: relative;
}

.column:empty {
  padding: 0;
}

.back_office {
  min-height: calc(100vh - var(--header-height));
  background-color: var(--color-30);
}

.back_office .menuColumn {
  width: 100%;
  min-width: 220px;
  max-width: 220px;
  padding-block-start: var(--spasing-m);
  background-color: var(--color-10);
  box-shadow: var(--shadow-20);
}

.officeWrap {
  width: 100%;
  padding: var(--spasing-m);
  background-color: var(--color-40);
}

.officeWrap .officeColumn {
  width: 100%;
  background-color: var(--color-10);
  border-radius: var(--spasing-xs);
  overflow: hidden;
}
