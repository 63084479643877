.wrap {
  padding-block-end: var(--spasing-s);
}

.list {
  max-height: 300px;
  overflow-y: auto;
}

.search {
  margin-block-end: var(--spasing-xs);
  margin-bottom: var(--spasing-s);
  color: var(--color-70);
}

.item + .item {
  margin-block-start: var(--spasing-xs);
}