.wrapper {
  position: relative;
  /* width: calc(100vw - 283px); */
  padding-inline-start: var(--spasing-s);
  padding-inline-end: var(--spasing-s);
  overflow-x: scroll;
}

.nameCell {
  font-weight: 600;
  max-width: 130px;
}