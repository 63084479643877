.wrap {
  width: 100%;
  height: 100%;
  position: relative;
}

.input {
  width: 100%;
}

.resultsWrap {
  width: 100%;
  position: absolute;
  top: calc(100% + var(--spasing-xs));
}

.results {
  width: 100%;
}