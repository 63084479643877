:root {
  --spasing-xxs: 4px;
  --spasing-xs: 8px;
  --spasing-s: 16px;
  --spasing-m: 24px;
  --spasing-l: 32px;
  --spasing-xl: 48px;

  --font-size-xs: 12px;
  --font-size-s: 14px;
  --font-size-m: 16px;
  --font-size-l: 22px;
  --font-size-xl: 28px;

  --border-width-xs: 1px;
  --border-width-s: 2px;
  --border-width-m: 3px;
  --border-width-l: 4px;
  --border-width-xl: 6px;

  --border-radius-1: 12px;
  --border-radius-2: 20px;
  --border-radius-3: 8px;

  --header-height: 60px;
  --header-z-index: 9990;
}
