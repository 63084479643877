.inputWrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: var(--spasing-m);
}

.inputContainer {
  display: flex;
  align-items: flex-start;
}

.input {
  width: 100%;
}

.buttonWrap {
  box-sizing: border-box;
  border: 1px solid var(--color-40);
  border-radius: 8px;
  width: 48px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 26px 0 0 var(--spasing-s);
}

.button:hover {
  background-color: transparent;
}
