.button:hover {
    background-color: inherit
  }

.icon {
  fill: var(--color-70);
}

.chatButtonCell {
  padding-top: 11px;
}

.collapseIcon {
    fill: var(--color-10);
  }