.column {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding-left: var(--spasing-m);
  padding-right: var(--spasing-m);
}

.gray {
  background-color: var(--color-40);
}
