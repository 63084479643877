.wrap {
  background-color: var(--color-20);
  border-radius: var(--border-radius-2);
  border: var(--border-width-xs) solid transparent;
}

.primary {
  background-color: var(--color-20);
  border-color: var(--color-20);
}

.secondary {
  background-color: var(--color-10);
  border-color: var(--color-30);
}

.header {
  min-height: 50px;
  padding-inline-start: var(--spasing-m);
  padding-inline-end: var(--spasing-m);
}

.status {
  margin-inline-end: var(--spasing-s);
}

.title {
  color: var(--color-70);
  font-size: var(--font-size-xl);
  text-transform: uppercase;
  text-align: center;
}

.controls {
  margin-inline-start: var(--spasing-s);
}

.expand {
  margin-inline-start: var(--spasing-s);
}

.icon {
  width: 24px;
  min-width: 24px;
  height: 24px;
}

.body {
  padding: var(--spasing-s);
  border-top: var(--border-width-xs) solid transparent;
}

.primary .body {
  border-color: var(--color-40);
}

.secondary .body {
  border-color: var(--color-30);
}
