.button {
  text-transform: lowercase;
  justify-content: flex-start;
  padding-left: 0;
  width: inherit;
}

.button:hover {
  background-color: transparent;
}
