.wrap {
  position: relative;
  margin-block-end: calc(var(--font-size-s) * 1.2 + var(--spasing-xxs));
}

.label {
  padding-inline-start: calc(var(--border-radius-1) + var(--border-width-xs));
  margin-block-end: var(--spasing-xs);
}

.error {
  padding-block-start: var(--spasing-xxs);
  padding-inline-start: calc(var(--border-radius-1) + var(--border-width-xs));
  color: var(--color-110);
  /*position: absolute;*/
  /*top: 100%;*/
}

.hint {
  padding-block-start: var(--spasing-xxs);
  padding-inline-start: calc(var(--border-radius-1) + var(--border-width-xs));
}