.buttonWrapper {
  padding-inline-start: var(--spasing-s);
  padding-inline-end: var(--spasing-s);
}

.ativateButton {
  margin-top: var(--spasing-s);
}

.deactivateButton {
  margin-top: var(--spasing-s);
  border-color: var(--color-100);
  color: var(--color-100);
}

.deleteButton {
  margin-top: var(--spasing-s);
  border-color: var(--color-100);
}

.footer {
  min-height: 75px;
  align-items: center;
  padding-inline-start: var(--spasing-s);
  padding-inline-end: var(--spasing-s);
  border-top: 1px solid var(--color-40);
}

.uploadWrap {
  border: 1px solid var(--color-100);
  border-radius: var(--border-radius-2);
  margin-top: var(--spasing-s);
}

.upload {
  color: var(--color-100);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 38px;
  padding-inline-start: var(--font-size-s);
  padding-inline-end: var(--font-size-s);
  border: var(--border-width-xs) solid transparent;

  font-size: var(--font-size-s);
  font-weight: 500;
}
