.wrap {
  border: var(--border-width-xs) solid var(--color-50);
  border-radius: var(--border-radius-2);
}

.header {
  height: 60px;
  border-bottom: var(--border-width-xs) solid var(--color-50);
  color: var(--color-70);
  font-size: var(--font-size-l);
  text-transform: uppercase;
}

.body {}

.footer {
  height: 60px;
  margin-top: auto;
  border-top: var(--border-width-xs) solid var(--color-50);
}