.wrap {}

.header {
  padding-top: var(--spasing-s);
  text-transform: uppercase;
  padding-bottom: var(--spasing-s);
  border-top: var(--border-width-xs) solid var(--color-40);
  color: var(--color-70);
  font-size: var(--font-size-m);
}

.openIcon {
  width: var(--spasing-m);
  min-width: var(--spasing-m);
  height: var(--spasing-m);
  margin-inline-start: auto;
}

.body {

}