.wrap {
  margin-bottom: var(--spasing-m);
}

.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 64px;
}

.column {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: var(--spasing-m);
}

.nameInput {
  grid-column: 1/3;
}

.wrapButton {
  display: flex;
}

.iconButton {
  background-color: transparent;
  box-shadow: none;
}

.iconButton:hover {
  box-shadow: none;
  background-color: transparent;
  cursor: pointer;
}

.icon {
  width: 20px;
  height: 20px;
  margin-left: var(--spasing-s);
}

.btn {
  max-width: 200px;
  margin: 0 auto;
}
