:root {
  --font-family-1: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  /* colors kit */
  --color-00: #000000;
  --color-10: #FFFFFF;
  --color-20: #F6F6F6;
  --color-30: #ECECEC;
  --color-40: #E8E8E9;
  --color-50: #9CA3AF;
  --color-60: #6B7280;
  --color-70: #374151;
  --color-80: #32B768;
  --color-90: #30A25E;
  --color-100: #FF9F07;
  --color-110: #EB4646;

  /* shadows */
  --shadow-10: 0px 2px 4px rgba(0, 0, 0, 0.1);
  --shadow-20: 0px 2px 4px rgba(0, 0, 0, 0.03);
}
