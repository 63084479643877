.wrap {
  min-height: 40px;
  background-color: var(--color-10);
  color: var(--color-70);
  font-size: var(--font-size-s);
  font-weight: 500;
}

.subTitle {
  color: var(--color-60);
}
