.form {
  width: 100%;
  background-color: var(--color-10);
  padding: var(--spasing-s);
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  border-color: var(--color-30);
  border-radius: 0px 0px 20px 20px;
}

.form > * {
  margin-top: 0;
}

.container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: var(--spasing-l);
}

.category {
  color: var(--color-70);
  padding: 5px 0 29px 14px;
  font-weight: 600;
}

.inputGroup + .inputGroup {
  margin-block-start: var(--spasing-s);
}

.inputInline {
  margin-block-end: 0;
}

.bundle {
  width: 100%;
}

.currency {
  min-width: 122px;
  /* min-width: calc(100% + 100px) */
}

.inputInline + .inputInline {
  margin-inline-start: var(--spasing-m);
}

/* .deliveryPlace {
  height: calc(
    (40px * 2) + var(--spasing-s) + var(--spasing-xs) +
      (var(--font-size-s) * 1.2)
  );
} */

.comment {
  height: 100%;
}

.footer {
  margin-top: 16px;
  padding-top: 10px;
  min-height: 75px;
  padding-inline-start: var(--spasing-s);
  padding-inline-end: var(--spasing-s);
  border-radius: 0 0 var(--border-radius-2) var(--border-radius-2);
  border-top: 1px solid var(--color-40);
}

.buttonActive {
  max-width: 335px;
}

.buttonDraft {
  max-width: 335px;
  background-color: var(--shadow-10);
  color: var(--color-80);
  border-color: var(--color-80);
}
