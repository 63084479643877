.title {
  font-weight: 600;
  font-size: var(--font-size-m);
  padding-left: var(--spasing-m);
}

.inputGroupWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spasing-m) var(--spasing-xl);
  padding: 0 var(--spasing-m);
}
