.textarea {
  min-height: 40px;
  padding: var(--border-radius-1);
  border: var(--border-width-xs) solid var(--color-50);
  border-radius: var(--border-radius-1);
  color: var(--color-60);
  font-family: var(--font-family-1);
  font-size: var(--font-size-s);
  resize: none;
}

.textarea::placeholder {
  color: var(--color-50);
}

.textarea:focus {
  border-color: var(--color-60);
}

.textarea:disabled {
  background-color: var(--color-20);
  border-color: var(--color-40);
  color: var(--color-50);
}