.button {
  justify-content: flex-start;
  padding: var(--spasing-xs) var(--spasing-s);
  text-transform: none;
}

.button:hover {
  background-color: transparent;
}

.icon {
  margin-inline-end: var(--spasing-xs);
}