.wrap {
  width: 100%;
  height: calc(100vh - var(--header-height));
  position: fixed;
  top: var(--header-height);
  left: 0;
  overflow: hidden;
}

.wrap.visible {
  visibility: visible;
}

.wrap.hidden {
  visibility: hidden;
  transition-property: visibility;
  transition-delay: 300ms;
}

.left, .right {
  width: 100%;
  max-width: 375px;
  height: 100%;
  background-color: var(--color-10);
  box-shadow: -2px 0 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 0;
  z-index: calc(var(--header-z-index) - 1);
}

.left {
  left: 0;
}

.right {
  right: 0;
}

.left,
.left.enter {
  transform: translateX(-100%);
}

.left.enterActive {
  transform: translateX(0);
  transition: transform 300ms;
}

.left.enterDone {
  transform: translateX(0);
}

.left.exit {
  transform: translateX(0);
}

.left.exitActive {
  transform: translateX(-100%);
  transition: transform 300ms;
}

.left.exitDone {
  transform: translateX(-100%);
}

.right,
.right.enter {
  transform: translateX(100%);
}

.right.enterActive {
  transform: translateX(0);
  transition: transform 300ms;
}

.right.enterDone {
  transform: translateX(0);
}

.right.exit {
  transform: translateX(0);
}

.right.exitActive {
  transform: translateX(100%);
  transition: transform 300ms;
}

.right.exitDone {
  transform: translateX(100%);
}