.wrap {
  position: relative;
  cursor: pointer;
  color: var(--color-60);
  font-size: var(--font-size-s);
  text-transform: uppercase;
}

.disabled {
  color: var(--color-50);
}

.input {
  display: block;
  width: 155%;
  height: 100%;
  position: absolute;
  top: 0;
  left: -90px;
  opacity: 0;
  cursor: pointer;
}

.input:disabled {
  cursor: not-allowed;
 }

.addIcon {
  margin-inline-end: var(--spasing-s);
}