.wrap {
  min-width: 155px;
  max-width: 155px;
  padding-inline-end: var(--spasing-s);
  border-radius: 50px;
  border: var(--border-width-xs) solid var(--color-30);
  background-color: var(--color-10);
  position: relative;
}

.iconWrapper {
  width: 34px;
  min-width: 34px;
  height: 34px;
  margin-right: var(--spasing-xs);
  background: var(--color-40);
  border-radius: 50px;
}

.name {
  color: var(--color-70);
  font-size: var(--font-size-s);
  font-weight: 500;
}

.dropIcon {
  width: 14px;
  min-width: 14px;
  height: 14px;
  margin-inline-start: var(--spasing-xs);
}

.popup {
  width: 100%;
  border-radius: var(--spasing-xs);
  border: var(--border-width-xs) solid var(--color-30);
  background-color: var(--color-10);
  position: absolute;
  top: calc(100% + var(--spasing-xxs));
  left: 0;
  overflow: hidden;
}

.menu {
  border-top: none;
}

.item {
  padding: var(--spasing-xs);
}

.logout {
  padding: var(--spasing-xs);
  margin-block-start: var(--spasing-m);
}
