.inputGroupWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spasing-m) var(--spasing-xl);
  padding: 0 var(--spasing-m);
}

.addButton {
  background-color: var(--shadow-10);
  color: var(--color-80);
  border: 1px solid var(--color-80);
  border-radius: var(--border-radius-2);
  padding: var(--spasing-xs) var(--spasing-m);
  margin-left: auto;
  margin-right: var(--spasing-m);
}

.addButton:hover {
  background-color: transparent;
}

.addButton svg {
  margin-right: var(--spasing-xs);
}