.subtitleWrap {
  display: flex;
  margin: var(--spasing-m) 0;
}

.subtitle {
  text-transform: uppercase;
  color: var(--color-100);
  font-weight: 600;
  font-size: var(--font-size-m);
  line-height: 16px;
  white-space: nowrap;
}

.dotted {
  width: 100%;
  border-bottom: 1px dotted #d9d9d9;
  margin-left: 10px;
}
