.list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.item {
  cursor: pointer;
}

.item + .item {
  margin-block-start: 1px;
}