.wrap {
  min-width: 155px;
  max-width: 155px;
  padding-inline-end: var(--spasing-s);
  color: var(--color-10);
  position: relative;
  text-transform: none;
  min-width: 50px;
}

.wrap:hover {
  background-color: transparent;
}

.dropIcon {
  width: 14px;
  min-width: 14px;
  height: 14px;
  margin-inline-start: var(--spasing-xs);
}

.popup {
  width: 8%;
  border-radius: var(--spasing-xs);
  border: var(--border-width-xs) solid var(--color-30);
  background-color: var(--color-10);
  position: absolute;
  z-index: 1;
  overflow: hidden;
}

.select {
  border-bottom: var(--border-width-xs) solid var(--color-30);
}

.container {
  background-color: var(--color-10);
  border-radius: 18px;
  min-width: 550px;
}

.title {
  background-color: var(--color-60);
  color: var(--color-10);
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  text-align: center;
  text-transform: uppercase;
  padding: var(--spasing-s);
}

.form {
  padding-inline-start: var(--spasing-s);
  padding-inline-end: var(--spasing-s);
}

.commentField {
  height: 300px;
}

.button {
  margin-left: auto;
  padding: var(--spasing-s) var(--spasing-m);
}

.button:hover {
  background-color: transparent;
}

.buttonSubmit {
  margin-left: auto;
  border-radius: var(--border-radius-2);
  padding: var(--spasing-s) var(--spasing-m);
}
