.header {
  position: relative;
  padding: var(--spasing-xs);
  border-bottom: 2px solid #e5e5e5;
}

.icon {
  width: 30px;
  height: 30px;
  margin-left: var(--spasing-xs);
}
