.dateTitle {
  min-width: 300px;
  text-align: right;
  padding-right: var(--spasing-xxs);
}

.date {
  text-wrap: nowrap;
  font-weight: bold;
  margin-right: var(--spasing-s);
}

.modal {
  background-color: var(--color-10);
  padding: var(--spasing-xl);
  border-radius: var(--border-radius-2);
  width: 600px;
  text-align: center;
}

.title {
  margin-bottom: var(--spasing-xl);
}

.btn {
  margin-right: var(--spasing-l);
}

.arrowIcon {
  fill: transparent;
  stroke: currentColor;
}

.arrowIcon.active {
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spinner-grow {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
