.button:hover {
    background-color: inherit
  }

.icon {
  fill: var(--color-70);
}

.collapseIcon {
    fill: var(--color-10);
  }