.wrap {
  height: 100%;
}

.column:first-child {
  justify-content: flex-start;
}

.column:nth-child(2) {
  width: 100%;
}

.column:last-child {
  justify-content: flex-end;
}