.container {
  padding: var(--spasing-m);
}

.emptyList {
  text-align: center;
  padding-top: var(--spasing-l);
}

.addButton {
  color: var(--color-80);
  border: 1px solid var(--color-80);
  border-radius: var(--border-radius-2);
  min-width: 200px;
  padding: var(--spasing-xs);
  margin-left: auto;
  margin-right: var(--spasing-m);
  margin-bottom: var(--spasing-m);
  cursor: pointer;
}

.addButton:hover {
  background-color: transparent;
}
