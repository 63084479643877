.wrap {
  margin-bottom: var(--spasing-m);
}

.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spasing-s);
}

.input input {
  background: transparent !important;
  border: transparent;
}

.focus input {
  border: var(--border-width-xs) solid var(--color-40);
}

.wrapButton {
  display: flex;
}

.iconButton {
  background-color: transparent;
  box-shadow: none;
}

.iconButton:hover {
  box-shadow: none;
  background-color: transparent;
  cursor: pointer;
}

.btn {
  max-width: 230px;
  margin: var(--spasing-s) auto var(--spasing-xs);
}

.icon {
  width: 20px;
  height: 20px;
  margin-left: var(--spasing-s);
}

.addAddressBtn {
  color: var(--color-80);
  font-weight: 500;
  border: 1px solid var(--color-80);
  border-radius: 8px;
  margin-bottom: var(--spasing-m);
}

.addAddressBtn:hover {
  cursor: pointer;
  background: transparent;
}

.addAddressBtn svg {
  margin-right: var(--spasing-xs);
}
