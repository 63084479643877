.listItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--color-30);

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.03);
  border-radius: var(--border-radius-3);
  margin-top: var(--spasing-s);
  padding: var(--spasing-xs) var(--spasing-s);
  color: var(--color-70);
  font-size: var(--font-size-s);
  line-height: 1.2;
}

.open {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.03);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 1px solid transparent;
  box-shadow: none;
}

.listItem:nth-child(even) {
  background-color: var(--color-20);
}

.item {
  width: 35%;
}

.switch {
  padding-right: var(--spasing-xs);
}

.arrowIcon {
  margin-left: var(--spasing-s);
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.row {
  margin-top: 0;
}

.cell {
  padding: 0 var(--spasing-m);
  border-left: 1px solid var(--color-30);
  border-right: 1px solid var(--color-30);
  border-bottom: 1px solid var(--color-30);
  border-bottom-left-radius: var(--border-radius-3);
  border-bottom-right-radius: var(--border-radius-3);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.03);
}

.form {
  margin-top: 0;
}

.input {
  width: 224px;
}

.btn {
  width: 96px;
}
