
  .createOffer {
    padding: 0
  }
  
  .cellOffer {
    padding: 0
  }
  
  .collapseDiv {
  margin-top: -16px;
  }
  
  .multiRow {
    background-color: var(--color-60);
  }
  
  .collapseCell {
    color: var(--color-10);
  }
  
  
  /* todo add variables */
  
  .collapseCellOpen {
    color: var(--color-10);
  }
  
  .collapseCellOpen:first-child {
    padding-left: var(--spasing-s);
    border-left: 1px solid var(--color-40);
    border-radius: var(--border-radius-1) 0 0 0;
  }
  
  .collapseCellOpen:last-child {
    border-right: 1px solid var(--color-40);
    border-radius: 0 var(--border-radius-1) 0 0;
    padding-right: var(--spasing-s);
  }
  
  .collapseCell,
  .th {
    border: none;
    border-top: 1px solid var(--color-40);
    border-bottom: 1px solid var(--color-40);
  }
  
  .collapseCell:first-child,
  .th:first-child {
    padding-left: var(--spasing-s);
    border-left: 1px solid var(--color-40);
    border-radius: var(--border-radius-1) 0 0 var(--border-radius-1);
  }
  
  .collapseCell:last-child,
  .th:last-child {
    border-right: 1px solid var(--color-40);
    border-radius: 0 var(--border-radius-1) var(--border-radius-1) 0;
    padding-right: var(--spasing-s);
  }
  
  .buttonCell {
    height: 0;
    padding: 0;
  
  }
  
  .button:hover {
    background-color: inherit
  }
  
  
  
  