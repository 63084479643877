
.wrap {
  height: 100%;
}

.footer {
  height: 50px;
  margin-block-start: auto;
  background-color: var(--color-20);
  box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.05);
  border-top: var(--border-width-xs) solid var(--color-50);
}