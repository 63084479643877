.wrap {
  min-width: 200px;
  max-height: 200px;
  background-color: var(--color-30);
  border: var(--border-width-xs) solid var(--color-30);
  border-radius: var(--spasing-xs);
  box-shadow: var(--shadow-10);
  overflow: hidden;
}

.body {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}