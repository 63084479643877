.form {
  background-color: #fbfbfb;
  padding: var(--spasing-s) var(--spasing-s) 0 var(--spasing-s);
  border: 1px solid var(--color-40);
  border-radius: 0px 0px 20px 20px;
  display: flex;
  justify-content: space-between;
}

.statusGroup {
  justify-content: center;
  min-height: calc(30px + var(--spasing-s) + (var(--font-size-m) * 1.2));
}

.inputGroup {
  background-color: var(--color-10);
  width: 100%;
  border-radius: var(--border-radius-1);
  padding: var(--spasing-m) var(--spasing-m) 0 var(--spasing-m);
  margin-bottom: var(--spasing-m);
}

.inputGroup + .inputGroup {
  margin-block-start: var(--spasing-s);
  margin-left: var(--spasing-m);
}

.inputInline {
  margin-block-end: var(--spasing-s);
  width: 100%;
  color: transparent;
}

.inputInline > label {
  padding-left: 0;
}

.inputInline > textarea {
  padding: 0;
}

.inputInline > textarea:disabled {
  background-color: transparent;
  border-color: transparent;
  color: var(--color-70);
  padding-left: 0;
}

.input > input:disabled {
  background-color: transparent;
  border-color: transparent;
  color: var(--color-70);
  padding-left: 0;
}

.select > select:disabled {
  background-color: transparent;
  border-color: transparent;
  color: var(--color-00);
  padding-left: 0;
}

.titleWrap {
  width: 100%;
  margin-bottom: var(--spasing-m);
  text-transform: uppercase;
  font-weight: 600;
  color: var(--color-100);
}

.dotted {
  display: block;
  width: 100%;
  padding-left: var(--spasing-xs);
  border-bottom: 1px dotted var(--color-40);
}
