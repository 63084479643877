.wrap {
  width: calc(100% - (var(--spasing-s) * 2));
  max-width: 1200px;
  padding: var(--spasing-l) var(--spasing-l) var(--spasing-s);
  background-color: var(--color-20);
  border-radius: var(--border-radius-1);
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.2);
  position: fixed;
  top: var(--spasing-s);
  left: 50%;
  transform: translateX(-50%);
  z-index: calc(var(--header-z-index) + 9);
}

.tag {
  font-size: var(--font-size-xs);
  font-weight: 700;
  position: absolute;
  top: var(--spasing-s);
  left: var(--spasing-s);
}

.error {
  color: red;
}

.info {
  color: var(--color-50);
}

.success {
  color: green;
}

.message {
  color: var(--color-60);
  font-size: var(--font-size-s);
  white-space: pre-wrap;
}

.button {
  position: absolute;
  top: var(--spasing-xs);
  right: var(--spasing-xs);
}
