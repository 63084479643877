.wrap {
  padding: var(--spasing-s);
}

.offer + .offer {
  margin-block-start: var(--spasing-s);
}

.checkBoxCell {
  /* padding-inline-end: 8px; */
  /* max-width: 9%; */
}

.checkBox {
  border: var(--border-width-s) solid var(--color-10);
}

.buttonCell {
  min-width: 40px;
}

.categoryCell {
  /* padding-inline-end: 35px; */
  /* min-width: 63px; */
  /* max-width: 9%;
  padding: 8px; */
}

.brendCell {
  /* max-width: 9%;
  padding: 8px; */
}

.productCell {
  /* padding: 8px;
  max-width: 15%; */
}

.quantityCell {
  /* padding: 8px;
  max-width: 9%; */
}

.alternativeCell {
  /* padding: 8px;
  max-width: 9%; */
}

.priceCell {
  padding: 8px;
  max-width: 10%;
}

.currencyCell {
  /* padding: 8px;
  max-width: 9%; */
}

.paymentCell {
  /* padding: 8px;
  max-width: 9%; */
}

.deliveryCell {
  padding: 8px;
  max-width: 9%;
}

.statusCell {
  padding: 8px;
  max-width: 9%;
}

.dateCell {
  padding: 8px;
  max-width: 9%;
}

.actionsCell {
  padding-inline-end: var(--spasing-m);
  text-align: -webkit-right;
}

.icon {
  fill: var(--color-70);
}

.expand:hover {
  background-color: inherit;
}

.iconExpand {
  min-width: 24px;
  height: 24px;
  fill: var(--color-10);
}
