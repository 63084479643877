.wrap {
  margin-top: 27vh;
  width: 100%;
  max-width: 335px;
  padding-left: var(--spasing-xs);
  padding-right: var(--spasing-xs);
  color: #000000;
}

.header {
  font-size: var(--font-size-xl);
  text-transform: uppercase;
}

.subheader {
  margin-top: var(--spasing-xs);
}

.subheader,
.header {
  text-align: center;
  color: var(--color-70);
}

.logo {
  width: 250px;
  height: 110px;
}
