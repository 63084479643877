.wrap {
  padding: var(--spasing-l)  var(--spasing-s);
}

.button {
  min-height: 50px;
  background-color: var(--color-20);
  border: var(--border-width-xs) dashed var(--color-70);
}

.addIcon {
  margin-inline-end: var(--spasing-s);
}