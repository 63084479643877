.form {
  width: 100%;
  background-color: var(--color-10);
  padding: var(--spasing-s) var(--spasing-s) 0 var(--spasing-s);
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  border-color: var(--color-30);
  border-radius: 0px 0px 20px 20px;
}

.statusGroup {
  justify-content: center;
  min-height: calc(30px + var(--spasing-s) + (var(--font-size-m) * 1.2));
  grid-column: 1/5;
}

.container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: var(--spasing-l);
}

.duplicateButton {
  margin-inline-start: var(--spasing-s);
  margin-inline-end: var(--spasing-s);
  border-color: var(--color-80);
  color: var(--color-80);
}

.footer {
  margin-top: 50px;
  min-height: 75px;
  align-items: center;
  padding-inline-start: var(--spasing-s);
  padding-inline-end: var(--spasing-s);
  border-top: 1px solid var(--color-40);
}

.deleteButton {
  border-color: var(--color-110);
  color: var(--color-110);
}

.inputWrap {
  display: flex;
  align-items: center;
}

.currency {
  display: inline-block;
  background-color: var(--color-20);
  padding: var(--spasing-xs);
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border: 1px solid var(--color-30);
  border-right: none;
  margin-top: 6px;
  line-height: 1.6;
}

.fieldWithCurrency {
  width: 100%;
}

.fieldWithCurrency label {
  margin-left: -46px;
}

.fieldWithCurrency input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
