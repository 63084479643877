.wrap {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
}

.column {
  position: relative;
}

.column:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.column:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.twoColumns_50X50 .column {
  width: 50%;
  min-height: 100vh;
  position: relative;
}

.twoColumns_fluid .column:last-child {
  width: 100%;
}

.personal_office {
  min-height: calc(100vh - var(--header-height));
}

.personal_office .grid {
  display: grid;
  grid-template-columns: 1fr 700px;
  min-height: 488px;
  padding: var(--spasing-m);
}

.personal_office .column {
  height: 100%;
  /*padding: var(--spasing-m);*/
  border: 1px solid var(--color-40);
}
