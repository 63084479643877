.item {
  max-width: 200px;
  width: auto;
  height: 32px;
  cursor: pointer;
  text-transform: capitalize;
  color: var(--color-70);
}

.icon {
  width: 16px;
  height: 16px;
  margin-inline-start: var(--spasing-xs);
}
