.box {
  display: flex;
}

.justify_center {
  justify-content: center;
}

.justify_start {
  justify-content: flex-start;
}

.justify_end {
  justify-content: flex-end;
}

.justify_between {
  justify-content: space-between;
}

.justify_around {
  justify-content: space-around;
}

.align_center {
  align-items: center;
}

.align_start {
  align-items: flex-start;
}

.align_end {
  align-items: flex-end;
}

.align_baseline {
  align-items: baseline;
}

.align_stretch {
  align-items: stretch;
}

.direction_row {
  flex-direction: row;
}

.direction_rowReverse {
  flex-direction: row-reverse;
}

.direction_column {
  flex-direction: column;
}

.direction_columnReverse {
  flex-direction: column-reverse;
}

.wrap_nowrap {
  flex-wrap: nowrap;
}

.wrap_wrap {
  flex-wrap: wrap;
}

.wrap_wrapReverse {
  flex-wrap: wrap-reverse;
}

.margin_top_xxs {
  margin-block-start: var(--spasing-xxs);
}
.margin_bottom_xxs {
  margin-block-end: var(--spasing-xxs);
}
.margin_left_xxs {
  margin-inline-start: var(--spasing-xxs);
}
.margin_right_xxs {
  margin-inline-end: var(--spasing-xxs);
}

.margin_top_xs {
  margin-block-start: var(--spasing-xs);
}

.margin_bottom_xs {
  margin-block-end: var(--spasing-xs);
}

.margin_left_xs {
  margin-inline-start: var(--spasing-xs);
}

.margin_right_xs {
  margin-inline-end: var(--spasing-xs);
}

.margin_top_s {
  margin-block-start: var(--spasing-s);
}

.margin_bottom_s {
  margin-block-end: var(--spasing-s);
}

.margin_left_s {
  margin-inline-start: var(--spasing-s);
}

.margin_right_s {
  margin-inline-end: var(--spasing-s);
}

.margin_top_m {
  margin-block-start: var(--spasing-m);
}

.margin_bottom_m {
  margin-block-end: var(--spasing-m);
}

.margin_left_m {
  margin-inline-start: var(--spasing-m);
}

.margin_right_m {
  margin-inline-end: var(--spasing-m);
}

.margin_top_l {
  margin-block-start: var(--spasing-l);
}

.margin_bottom_l {
  margin-block-end: var(--spasing-l);
}

.margin_left_l {
  margin-inline-start: var(--spasing-l);
}

.margin_right_l {
  margin-inline-end: var(--spasing-l);
}

.margin_top_xl {
  margin-block-start: var(--spasing-xl);
}

.margin_bottom_xl {
  margin-block-end: var(--spasing-xl);
}

.margin_left_xl {
  margin-inline-start: var(--spasing-xl);
}

.margin_right_xl {
  margin-inline-end: var(--spasing-xl);
}

.padding_top_xxs {
  padding-block-start: var(--spasing-xxs);
}
.padding_bottom_xxs {
  padding-block-end: var(--spasing-xxs);
}
.padding_left_xxs {
  padding-inline-start: var(--spasing-xxs);
}
.padding_right_xxs {
  padding-inline-end: var(--spasing-xxs);
}

.padding_top_xs {
  padding-block-start: var(--spasing-xs);
}

.padding_bottom_xs {
  padding-block-end: var(--spasing-xs);
}

.padding_left_xs {
  padding-inline-start: var(--spasing-xs);
}

.padding_right_xs {
  padding-inline-end: var(--spasing-xs);
}

.padding_top_s {
  padding-block-start: var(--spasing-s);
}

.padding_bottom_s {
  padding-block-end: var(--spasing-s);
}

.padding_left_s {
  padding-inline-start: var(--spasing-s);
}

.padding_right_s {
  padding-inline-end: var(--spasing-s);
}

.padding_top_m {
  padding-block-start: var(--spasing-m);
}

.padding_bottom_m {
  padding-block-end: var(--spasing-m);
}

.padding_left_m {
  padding-inline-start: var(--spasing-m);
}

.padding_right_m {
  padding-inline-end: var(--spasing-m);
}

.padding_top_l {
  padding-block-start: var(--spasing-l);
}

.padding_bottom_l {
  padding-block-end: var(--spasing-l);
}

.padding_left_l {
  padding-inline-start: var(--spasing-l);
}

.padding_right_l {
  padding-inline-end: var(--spasing-l);
}

.padding_top_xl {
  padding-block-start: var(--spasing-xl);
}

.padding_bottom_xl {
  padding-block-end: var(--spasing-xl);
}

.padding_left_xl {
  padding-inline-start: var(--spasing-xl);
}

.padding_right_xl {
  padding-inline-end: var(--spasing-xl);
}