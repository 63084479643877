.container {
  border-bottom: 1px solid var(--color-40);
  padding-top: var(--spasing-xs);
  padding-bottom: var(--spasing-xs);
}

.title {
  color: var(--color-70);
  font-size: var(--font-size-m);
  text-transform: uppercase;
  text-align: center;
}

.button {
  position: absolute;
  margin-left: var(--spasing-l);
  cursor: pointer;
}

.button span {
  padding-top: 2px;
}

.button:hover {
  background-color: transparent;
  color: var(--color-80);
}

.icon {
  margin-right: var(--spasing-xs);
}