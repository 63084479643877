.inputContainer {
  display: flex;
  align-items: flex-end;
}

.input {
  width: 100%;
  margin-right: var(--spasing-s);
}

.buttonWrapper {
  display: flex;
}

.buttonWrap {
  box-sizing: border-box;
  border: 1px solid var(--color-40);
  border-radius: 8px;
  width: 48px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 12px 22px 0;
}

.buttonWrap:nth-child(4) {
  margin-left: 12px;
}
