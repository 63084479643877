.wrap {
  background-color: var(--color-10);
  border-radius: var(--border-radius-1);
  position: relative;
}

.select {
  -moz-appearance:none;
  -webkit-appearance:none;
  appearance:none;
  width: 100%;
  height: 40px;
  padding: 0 var(--border-radius-1);
  background-color: transparent;
  border: var(--border-width-xs) solid var(--color-40);
  border-radius: var(--border-radius-1);
  color: var(--color-70);
  font-size: var(--font-size-s);
  position: relative;
  z-index: 2;
  cursor: pointer;
}

.select[disabled] {
  cursor: default;
}

.select:focus {
  border-color: var(--color-60);
}

.select:disabled {
  background-color: var(--color-20);
  border-color: var(--color-40);
  color: var(--color-50);
}

.icon {
  position: absolute;
  width: var(--spasing-m);
  height: var(--spasing-m);
  right: calc(var(--border-radius-1) / 2);
  z-index: 1;
}