.addButtonWrapper {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid var(--color-40);
  padding-top: var(--spasing-s);
  padding-bottom: var(--spasing-s);
  padding-right: var(--spasing-s);
  margin: 0 -16px;
}

.addButton {
  max-width: 160px;
  cursor: pointer;
}

.button:hover {
  background-color: transparent;
}
