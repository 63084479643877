.container {
  padding: var(--spasing-m);
}

.listItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--color-30);
  border-radius: var(--border-radius-3);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.03);
  margin-bottom: var(--spasing-s);
  padding: var(--spasing-xs) var(--spasing-s);
  color: var(--color-70);
  font-size: var(--font-size-s);
  line-height: 1.2;
}

.listItem:nth-child(even) {
  background-color: var(--color-20);
}

.item {
  width: 35%;
}

.icon:hover {
  cursor: pointer;
}

.addButton {
  color: var(--color-80);
  border: 1px solid var(--color-80);
  border-radius: var(--border-radius-2);
  min-width: 200px;
  padding: var(--spasing-xs);
  margin-left: auto;
  margin-right: var(--spasing-m);
  cursor: pointer;
}

.addButton:hover {
  background-color: transparent;
}

.iconPlus {
  margin-right: var(--spasing-xs);
}

.emptyList {
  text-align: center;
  padding-top: var(--spasing-l);
}

.modal {
  background-color: var(--color-10);
  padding: var(--spasing-l);
  border-radius: 8px;
}

.buttonWrapper {
  display: flex;
  margin-top: var(--spasing-l);
}

.buttonReset {
  margin-right: var(--spasing-m);
  border: 1px solid var(--color-60);
}
