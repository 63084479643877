.offer {
  background-color: var(--color-100);
  color: var(--color-10);
  border-radius: var(--border-radius-1);
  height: 18px;
  width: 28px;
}

.deal {
  background-color: var(--color-90);
  color: var(--color-10);
  border-radius: var(--border-radius-1);
  height: 18px;
  width: 28px;
}
