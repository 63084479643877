.nav {
  display: flex;
  padding-bottom: var(--spasing-s);
  border-bottom: 1px solid var(--color-40);
  padding-left: 0;
}

.navItem {
  list-style: none;
  margin-right: var(--spasing-s);
  color: var(--color-00);
}

.button {
  background: none !important;
  border: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
}

.active {
  position: relative;
  color: var(--color-90);
}

.active:after {
  position: absolute;
  bottom: -16px;
  left: 0;
  content: '';
  height: 2px;
  width: 100%;
  background-color: var(--color-90);
}
