.container {
  background-color: var(--color-10);
  border-radius: 24px;
  padding: var(--spasing-xl);
  margin-bottom: var(--spasing-m);
}

.inputWrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: var(--spasing-m);
}

.wrapButton {
  width: 200px;
  display: flex;
  margin-left: auto;
}

.button {
  border: 1px solid var(--color-00);
  margin-right: 24px;
}

.commentField {
  min-height: 100px;
}

.editorContent {
  border: var(--border-width-xs) solid var(--color-40);
  margin-bottom: var(--spasing-l);
  border-radius: var(--border-radius-1);
  padding-left: var(--spasing-s);
  padding-right: var(--spasing-s);
  min-height: 48px;
  background-color: var(--color-20);
  color: var(--color-50);
}

.editor {
  margin-bottom: var(--spasing-l);
}
