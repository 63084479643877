.link {
  min-height: 40px;
  padding: var(--spasing-xs) var(--spasing-xs) var(--spasing-xs) var(--spasing-s);
  color: var(--color-70);
  font-size: var(--font-size-s);
}

.subItem.link {}

.active {
  background-color: var(--color-80);
  color: var(--color-10);
}

.subItem.active {
  background-color: var(--color-20);
  color: var(--color-80);
}

.iconWrap {
  width: 24px;
  min-width: 24px;
  height: 24px;
  margin-inline-end: var(--spasing-xs);
}

.icon {
}

.expandIcon {
  width: 24px;
  min-width: 24px;
  height: 24px;
  margin-inline-start: var(--spasing-xs);
}
