.wrap {
  height: 60px;
}

.link {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: var(--font-size-m);
  font-weight: 600;
  color: var(--color-80);
  padding-left: var(--spasing-m);
}

.icon {
  width: 44px;
  height: 56px;
}
