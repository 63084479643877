.table {
  border-radius: 8px;
  overflow: hidden;
  box-sizing: none;
}

.head {
  background-color: var(--color-10);
  min-height: 48px;
}

.th {
  background-color: var(--color-10);
}

.createOffer {
  padding: 0
}

.cellOffer {
  padding: 0
}

.collapseDiv {
  margin-top: -16px;
}

.cell {
  border: 1px solid var(--color-40);
  background-color: var(--color-30);
}

.disableForm {
  display: none;
}

/* todo add variables */

.table.round {
  border-collapse: separate;
  border-spacing: 0 1em;
}

.round .collapseCellOpen {
  border: none;
  border-top: 1px solid var(--color-40);
}

.round .collapseCellOpen:first-child {
  padding-left: var(--spasing-s);
  border-left: 1px solid var(--color-40);
  border-radius: var(--border-radius-1) 0 0 0;
}

.round .collapseCellOpen:last-child {
  border-right: 1px solid var(--color-40);
  border-radius: 0 var(--border-radius-1) 0 0;
  padding-right: var(--spasing-s);
}

.round .cell,
.round .collapseCell,
.round .th {
  border: none;
  border-top: 1px solid var(--color-40);
  border-bottom: 1px solid var(--color-40);
}

.round .cell:first-child,
.round .collapseCell:first-child,
.round .th:first-child {
  padding-left: var(--spasing-s);
  border-left: 1px solid var(--color-40);
  border-radius: var(--border-radius-1) 0 0 var(--border-radius-1);
}

.round .cell:last-child,
.round .collapseCell:last-child,
.round .th:last-child {
  border-right: 1px solid var(--color-40);
  border-radius: 0 var(--border-radius-1) var(--border-radius-1) 0;
  padding-right: var(--spasing-s);
}

.buttonCell {
  height: 0;
  padding: 0;

}

.button {  
  font-weight: 600;
  color: #FF9F07;
  max-height: 18px;
  text-transform: initial;
  justify-content: left;

}

.button:hover {
  background-color: inherit
}



