.container {
  background-color: var(--color-10);
  border-radius: 18px;
  min-width: 550px;
}

.title {
  background-color: var(--color-60);
  color: var(--color-10);
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  text-align: center;
  text-transform: uppercase;
  padding: var(--spasing-s);
}

.fields {
  padding: var(--spasing-s) var(--spasing-m);
}

.background {
  background-color: var(--color-20);
}

.button {
  margin-left: auto;
  padding: var(--spasing-s) var(--spasing-m);
}

.iconButton:hover, .button:hover {
  background-color: transparent;
}