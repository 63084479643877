.wrap {
  height: 100%;
}

.menu {
  margin-block-start: var(--spasing-m);
}

.wrap .logout {
  width: auto;
  justify-content: flex-start;
  margin-block-start: auto;
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding-left: var(--spasing-s);
}

.wrap .logout:hover {
  background-color: transparent;
}