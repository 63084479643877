.nav {
  display: flex;
  padding-left: var(--spasing-xl);
  padding-bottom: var(--spasing-m);
  border-bottom: 1px solid var(--color-40);
}

.navItem {
  list-style: none;
  margin-right: var(--spasing-s);
  color: var(--color-60);
}

.active {
  position: relative;
  color: var(--color-90);
}

.active:after {
  position: absolute;
  bottom: -24px;
  left: 0;
  content: '';
  height: 2px;
  width: 100%;
  background-color: var(--color-90);
}