.badge {
  color: var(--color-10);
  border-radius: var(--border-radius-1);
  margin-right: var(--spasing-xs);
  height: 18px;
  width: 28px;
}

.orange {
  background-color: var(--color-100);
}

.green {
  background-color: var(--color-90);
}

.mr {
  margin-right: var(--spasing-xs);
}
