.container {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-height: 320px;
  border: 2px dotted #5d6370;
  border-radius: var(--border-radius-3);
  margin: var(--spasing-m) 0 var(--spasing-xl) 0;
}

.wrapContainer {
  background-color: var(--color-10);
  border-radius: 24px;
  padding: var(--spasing-xl);
  margin-bottom: var(--spasing-m);
}

.iconWrap {
  transform: rotate(180deg);
  color: #6b7280;
  border: 1px dotted #5d6370;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spasing-s);
  margin-bottom: var(--spasing-m);
}

.iconWrap svg {
  width: 48px;
  height: 48px;
}

.title {
  color: #6b7280;
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
  margin-bottom: var(--spasing-xl);
}

.buttonWrap {
  position: relative;
}

.inputUpload {
  position: absolute;
  top: 0px;
  left: 100px;
  cursor: pointer;
  opacity: 0;
}

.image {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 50vh;
  padding: var(--spasing-xl);
}

.image img {
  max-width: 50vw;
  object-fit: contain;
}
