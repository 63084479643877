.wrap {
  padding: var(--spasing-s) var(--spasing-s) 0 var(--spasing-s);
}

.userId {
  font-size: var(--font-size-s);
  color: var(--color-60);
}

.name {
  color: var(--color-00);
  font-size: var(--font-size-l);
  font-weight: 500;
}