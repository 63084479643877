.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: var(--spasing-m);
  margin: var(--spasing-m);
}

.wrap {
  display: flex;
  align-items: center;
  margin: 0 var(--spasing-m);
}

.inputWrap {
  width: 100%;
  margin-right: var(--spasing-m);
}

.btn {
  width: 68px;
}
