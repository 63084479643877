.table {
  /*box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);*/
  border-radius: 8px;
  overflow: hidden;
}

.head {
  background-color: var(--color-10);
  /*box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);*/
}

.row:nth-child(even) {
  background-color: var(--color-20);
}

.cell {
  border: 1px solid var(--color-40);
  color: var(--color-70);
}
/* todo add variables */

.table.round {
  border-collapse: separate;
  border-spacing: 0 1em;
}

.round .cell,
.round .th {
  border: none;
  border-top: 1px solid var(--color-40);
  border-bottom: 1px solid var(--color-40);
}

.round .cell:first-child,
.round .th:first-child {
  padding-left: var(--spasing-s);
  border-left: 1px solid var(--color-40);
  border-radius: var(--border-radius-1) 0 0 var(--border-radius-1);
}

.round .cell:last-child,
.round .th:last-child {
  border-right: 1px solid var(--color-40);
  border-radius: 0 var(--border-radius-1) var(--border-radius-1) 0;
  padding-right: var(--spasing-s);
}