.th {
  background: var(--color-30);
}

.cell {
  height: 48px;
  padding: var(--spasing-xs);
  text-align: left;
  color: var(--color-70);
}

.item {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
}

.itemTh {
  font-weight: 600;
  color: var(--color-70);
}
