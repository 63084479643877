.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 38px;
  padding-inline-start: var(--font-size-s);
  padding-inline-end: var(--font-size-s);
  border: var(--border-width-xs) solid transparent;
  border-radius: var(--border-radius-2);
  font-size: var(--font-size-s);
  font-weight: 500;
  color: var(--color-60);
  text-transform: uppercase;
  background-color: transparent;
  cursor: pointer;
}

.button:disabled {
  color: var(--color-50);
  cursor: not-allowed;
}

.primary {
  background-color: var(--color-80);
  color: var(--color-20);
  box-shadow: var(--shadow-10);
}

.primary:disabled {
  background-color: var(--color-40);
  color: var(--color-50);
  border-color: var(--color-40);
}

.secondary {
  background-color: var(--color-10);
  border-color: var(--color-30);
  color: var(--color-70);
  box-shadow: var(--shadow-10);
}

.quaternary {  
  background-color: var(--color-100);
  color: var(--color-20);
  box-shadow: var(--shadow-10);
}

.tertiary, .tertiary:disabled {
  border-color: transparent;
}

.tertiary:hover,
.secondary:hover {
  background-color: var(--color-30);
}

.iconButton {
  width: auto;
  min-width: 24px;
  height: auto;
  min-height: 24px;
  padding: 0;
  border: none;
  border-radius: unset;
}

.icon {
  width: 24px;
  height: 24px;
}