.wrapper { 
  padding-inline-start: var(--spasing-s);
  padding-inline-end: var(--spasing-s);
}

.filter {
  width: 216px;
}

.filter + .filter {
  margin-left: var(--spasing-m);
}

.alternatives {
  margin-right: var(--spasing-m);
}