.wrap {
  position: relative;
}

.trigger {
  width: 100%;
}

.search {
  width: 100%;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.searchUp {
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column-reverse;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.resultUp {
  margin-block-end: var(--spasing-xs);
}

.result {
  margin-block-start: var(--spasing-xs);
}
