.table {
  border-radius: 8px;
  overflow: hidden;
  box-sizing: none;
}

.head {
  min-height: 48px;
}

.collapseCell {
  border: 1px solid var(--color-40);
  color: var(--color-70);
}

.cell {
  border: 1px solid var(--color-40);
  color: var(--color-10);
  background: var(--color-60);
}

/* todo add variables */

.table.round {
  border-collapse: separate;
  border-spacing: 0 1em;
}

.round .cell,
.round .collapseCell,
.round .th {
  border: none;
  border-top: 1px solid var(--color-40);
  border-bottom: 1px solid var(--color-40);
}

.round .cell:first-child,
.round .collapseCell:first-child,
.round .th:first-child {
  padding-left: var(--spasing-s);
  border-left: 1px solid var(--color-40);
  border-radius: var(--border-radius-1) 0 0 var(--border-radius-1);
}

.round .cell:last-child,
.round .collapseCell:last-child,
.round .th:last-child {
  border-right: 1px solid var(--color-40);
  border-radius: 0 var(--border-radius-1) var(--border-radius-1) 0;
  padding-right: var(--spasing-s);
}

.linkCell {
  height: 0;
  padding: 0 0 0 20px ;
  color: #FF9F07;
  font-weight: 600;
}



