.wrap {
  border-bottom: 1px solid var(--color-40);
  padding: var(--spasing-s) 0;
}

.listItem {
  list-style: none;
  margin-right: var(--spasing-xs);
}

.link {
  display: flex;
  background-color: var(--color-10);
  border-color: var(--color-30);
  color: var(--color-70);
  box-shadow: var(--shadow-10);
  border-radius: var(--border-radius-2);
  padding: var(--spasing-xs) var(--spasing-s);
  white-space: nowrap;
  margin-bottom: 2px;
}

.active {
  background-color: var(--color-80);
  color: var(--color-20);
  box-shadow: var(--shadow-10);
}

