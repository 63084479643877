.wrap {
  margin-right: var(--spasing-m);
}

.filter {
  width: 216px;
}

.filter + .filter {
  margin-left: var(--spasing-m);
}

.label {
  padding-left: var(--spasing-xs);
  margin-bottom: var(--spasing-xxs);
}