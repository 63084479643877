.buttonWrapper {
  padding-inline-start: var(--spasing-s);
  padding-inline-end: var(--spasing-s);
}

.ativateButton {
  margin-top: var(--spasing-s);
}

.deactivateButton {
  margin-top: var(--spasing-s);
  border-color: var(--color-100);
  color: var(--color-100)
}

.deleteButton {
  margin-top: var(--spasing-s);
  border-color: var(--color-100);
}

.footer {
  min-height: 75px;
  align-items: center;
  padding-inline-start: var(--spasing-s);
  padding-inline-end: var(--spasing-s);
  border-top: 1px solid var(--color-40);
}