.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.col-2 {
  grid-template-columns: repeat(2, 1fr);
}

.col-4 {
  grid-template-columns: repeat(4, 1fr);
}

.col-5 {
  grid-template-columns: repeat(5, 1fr);
}

.col-6 {
  grid-template-columns: repeat(6, 1fr);
}

.col-7 {
  grid-template-columns: repeat(7, 1fr);
}

.col-8 {
  grid-template-columns: repeat(8, 1fr);
}

.col-9 {
  grid-template-columns: repeat(9, 1fr);
}

.column {
}

.item + .item {
  margin-block-start: var(--spasing-m);
}
