.wrap {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  background-color: rgba(126, 126, 126, 0.15);
  z-index: 20;
}

.wrap.visible {
  visibility: visible;
}

.wrap.hidden {
  visibility: hidden;
  transition-property: visibility;
  transition-delay: 300ms;
}

.shutter {
  width: 100%;
  max-width: 300px;
  height: calc(100% - var(--header-height) - 4px);
  box-shadow: -2px 0 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 0;
  overflow: auto;
  z-index: calc(var(--header-z-index) + 1);
}

.withHeader {
  top: var(--header-height);
  height: calc(100% - var(--header-height));
}

.light {
  background-color: var(--color-10);
}

.dark {
  background-color: var(--color-00);
}

.left {
  left: 0;
  top: var(--header-height);
  border-radius: 0 10px 10px 0;
}

.right {
  right: 0;
  top: calc(var(--header-height) + 4px);
  border-radius: 10px 0 0 10px;
}

.fullWidth {
  max-width: 100%;
}

.fullWidth.left,
.fullWidth.right {
  border-radius: 0;
}

.left,
.left.enter {
  transform: translateX(-100%);
}

.left.enterActive {
  transform: translateX(0);
  transition: transform 300ms;
}

.left.enterDone {
  transform: translateX(0);
}

.left.exit {
  transform: translateX(0);
}

.left.exitActive {
  transform: translateX(-100%);
  transition: transform 300ms;
}

.left.exitDone {
  transform: translateX(-100%);
}

.right,
.right.enter {
  transform: translateX(100%);
}

.right.enterActive {
  transform: translateX(0);
  transition: transform 300ms;
}

.right.enterDone {
  transform: translateX(0);
}

.right.exit {
  transform: translateX(0);
}

.right.exitActive {
  transform: translateX(100%);
  transition: transform 300ms;
}

.right.exitDone {
  transform: translateX(100%);
}
