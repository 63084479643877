.productCell {
  min-width: 100px;
}

.quantityCell {
  min-width: 65px;
}

.conditionCell {
  min-width: 75px;
}

.statusCell {
  min-width: 70px;
  max-width: 90px;
}

.nameCell {
  min-width: 60px;
  font-weight: 600;
  max-width: 100px;
}

.lastNameCell {
  min-width: 75px;
}

.placeCell {
  min-width: 105px;
}

.paymentCell {
  min-width: 75px;
}

.alternativeCell {
  min-width: 85px;
}

.dateCell {
  min-width: 90px;
  max-width: 90px;
}

.actionsCell {
  text-align: -webkit-right;
}

.button:hover {
  background-color: inherit;
}

.auction {
  background-color: var(--color-100);
  border-radius: var(--border-radius-1);
  height: 18px;
  width: 28px;
}

.tender {
  background-color: var(--color-90);
  border-radius: var(--border-radius-1);
  height: 18px;
  width: 28px;
}
