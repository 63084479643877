.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: var(--spasing-xl);
  padding-left: var(--spasing-m);
  padding-right: var(--spasing-m);
}

.submit {
  max-width: 200px;
  margin-left: auto;
  margin-right: var(--spasing-m);
}
