.wrap {
  padding-inline-start: var(--spasing-s);
  padding-inline-end: var(--spasing-s);
  display: grid;
  gap: var(--spasing-s);
}

.card :first-child :nth-child(2) {
  margin-inline-end: 70px;
}



