.container {
  display: grid;
  grid-template-columns: repeat(1, 258px);
  grid-gap: var(--spasing-xs);
  margin-left: var(--spasing-m);
}

.title {
  font-weight: 600;
  font-size: var(--font-size-m);
  padding-left: var(--spasing-m);
}
