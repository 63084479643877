.wrap {
  height: 50px;
  width: 100%;
  padding-inline-start: calc((var(--spasing-s) * 2) + 24px);
  padding-inline-end: var(--spasing-s);
  border-bottom: var(--border-width-xs) solid var(--color-50);
  box-shadow: var(--shadow-10);
}

.title {
  color: var(--color-00);
  font-size: var(--font-size-m);
  font-weight: 500;
  text-align: center;
}

.button {
  margin-inline-start: var(--spasing-s);
}

.icon {
  width: 24px;
  min-width: 24px;
  height: 24px;
}