.root {
  height: 48px;
  margin-right: 20px;
  width: 65px;
  position: relative;
  font-weight: 500;
  font-size: var(--font-size-m);
  font-family: var(--font-family-1);
}

:global(.desktop) .root{
  margin-bottom: 10px;
}
