.item {
  height: 42px;
  padding-inline-start: var(--spasing-s);
  padding-inline-end: var(--spasing-s);
  border-bottom: var(--border-width-xs) solid var(--color-50);
  color: var(--color-60);
  font-size: var(--font-size-s);
}

.button {
  margin-inline-start: var(--spasing-s);
}

.button:hover {
  background-color: transparent;
}

.icon {
  width: 24px;
  min-width: 24px;
  height: 24px;
}