.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}

.button {
  text-transform: capitalize;
  justify-content: flex-start;
  cursor: pointer;
}

.nav-horizontal {
}

.nav-vertical {
  flex-direction: column;
}

.navItem {
  margin-right: var(--spasing-m);
  margin-bottom: var(--spasing-s);
}

.navItem:last-of-type {
  margin-right: 0;
}

.nav-pills {
  border-radius: var(--border-radius-1);
}

.nav-link {
}

.nav-tabs {
}
