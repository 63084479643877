.item {
  border-bottom: transparent;
}

.item:nth-child(even) {
  background-color: var(--color-20);
}

.modal {
  background-color: var(--color-10);
  padding: var(--spasing-l);
  border-radius: 8px;
}

.buttonWrapper {
  display: flex;
  margin-top: var(--spasing-l);
}

.buttonReset {
  margin-right: var(--spasing-m);
  border: 1px solid var(--color-60);
}

.button:hover {
  background-color: transparent;
}