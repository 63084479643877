.wrap {
  min-height: var(--spasing-m);
  min-width: var(--spasing-m);
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.input {
  margin: 0;
  height: 0;
  width: 0;
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  width: 18px;
  height: 18px;
  margin: calc((var(--spasing-m) - 18px) / 2);
  border: var(--border-width-s) solid var(--color-70);
  border-radius: var(--border-width-xl);
  position: absolute;
  left: 0;
}

.checkmark:after {
  content: "";
  display: none;
  position: absolute;
  left: 4px;
  top: 1px;
  width: 4px;
  height: 7px;
  border: solid var(--color-10);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.input:checked ~ .checkmark {
  background-color: var(--color-100);
  border-color: var(--color-100);
}

.input:checked ~ .checkmark:after {
  display: block;
}

.label {
  margin-inline-start: calc(var(--spasing-m) + var(--spasing-xs));
  color: var(--color-70);
  font-size: var(--font-size-xs);
}